import React from 'react'
import {Container, Row} from 'react-bootstrap'
import {SEO, Footer, BigButton} from '../components'
import Highlight from '../components/Highlight'
import Title from '../components/Title'

const pageCertificate = {
  jobCode: 'GB-NON-05751',
  dateOfPrep: 'January 2023',
}

const NotFoundPage: React.FC = () => (
  <>
    <SEO title="Page not found" />
    <main className="content">
      <section id="not-found" className="py-5">
        <Container>
          <Row className="mb-3">
            <Title color="#324251" size="3.5rem">
              Whoops!
            </Title>
          </Row>
          <Row className="d-none d-sm-block">
            <Highlight title="Page not found." highlightText="404">
              Not to worry.
            </Highlight>
          </Row>
          <Row>
            <h2 className="d-block d-sm-none mb-2">
              Page not found. Not to worry.
            </h2>
            <p>
              You can head over to our homepage where you can find more
              information.
            </p>
            <BigButton href="/">Go to homepage</BigButton>
          </Row>
        </Container>
      </section>
    </main>
    <Footer certificate={pageCertificate} />
  </>
)

export default NotFoundPage
